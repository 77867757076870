import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { Layout, FormInput, FormTextArea } from '@common';
import { Container, Button } from '@components/global';
import { useFormInput, changeToUrl } from '@utils';
import { StyledSection, FormContainer, EndEventContainer } from './style';

const FeedbackForm = ({ title }) => {
  const nama = useFormInput('');
  const email = useFormInput('');
  const confirmEmail = useFormInput('');
  const telepon = useFormInput('');
  const message = useFormInput('');

  const [isEmailAndNameTrue, setIsEmailAndNameTrue] = useState(false);
  const [sendForm, setSendForm] = useState({ sent: false, success: false });

  const sendData = async () => {
    if (nama.value === '' || email.value === '' || telepon.value === '') {
      alert('Anda wajib mengisi nama, email, dan telepon.');
    } else if (!isEmailAndNameTrue) {
      alert(
        'Anda wajib menyatakan bahwa nama dan email telah tertulis dengan benar.'
      );
    } else if (email.value !== confirmEmail.value) {
      alert('Mohon periksa kembali email Anda.');
    } else {
      await firebase
        .database()
        .ref(
          `/events/${changeToUrl(title)}/feedback/12/${nama.value}-${ /*Todo: Ganti HARDCODE Webminar Series */
            telepon.value
          }`
        )
        .set({
          nama: nama.value,
          email: email.value,
          telepon: telepon.value,
          message: message.value
        })
        .then(() => {
          setSendForm({ sent: true, success: true });
        })
        .catch(() => {
          setSendForm({ sent: true, success: false });
        });
    }
  };

  return (
    <>
      <h2>Feedback</h2>
      <h3 style={{ textAlign: 'center', marginBottom: '24px' }}>{title}</h3>
      <FormContainer>
        <div>
          <FormInput data={nama} type="text" label="Nama" />
          <FormInput data={email} type="email" label="Email" />
          <FormInput
            data={confirmEmail}
            type="email"
            label="Konfirmasi Email"
          />
          <FormInput data={telepon} type="number" label="No. Telepon" />
          <FormTextArea data={message} rows="10" label="Feedback" />
          <div style={{ marginTop: '8px' }}>
            <input
              type="checkbox"
              checked={isEmailAndNameTrue}
              onChange={() => {
                setIsEmailAndNameTrue(!isEmailAndNameTrue);
              }}
            />{' '}
            Saya menyatakan bahwa email dan nama yang tertulis sudah benar.{' '}
            <br />
          </div>
        </div>
      </FormContainer>
      <div style={{ textAlign: 'center', marginTop: '32px' }}>
        <Link to={'..'}>
          <Button style={{ marginRight: '8px' }}>Kembali</Button>
        </Link>
        {!sendForm.sent ? (
          <Button onClick={sendData}>Kirim</Button>
        ) : (
          <p style={{ marginTop: '12px', fontWeight: 'bold' }}>
            {sendForm.success ? 'Terima Kasih' : 'Error Occured'}
          </p>
        )}
      </div>
    </>
  );
};

const FeedbackTemplate = ({ data }) => {
  const event = data.event;
  const inputPassword = useFormInput('');
  const oneHour = 3600000;
  return (
    <Layout page={'Feedback ' + event.title} includeHeading={false}>
      <StyledSection>
        <Container>
          {inputPassword.value === 'SREECADIN12' && /* Todo: ganti jadi dynamic */
            new Date().getTime() < Number(event.timestamp)+3*24*oneHour ? (
            <FeedbackForm title={event.title} />
          ) : (
            <>
            <EndEventContainer style={{ height: '15vh' }}>
            <h3 style={{ textAlign:'center' }}>
              Feedback <br />
              {event.title}
            </h3>
            </EndEventContainer>
            <FormInput data={inputPassword} type="text" label="Password" />
            </>
          )}
        </Container>
      </StyledSection>
    </Layout>
  );
};

export default FeedbackTemplate;
export const pageQuery = graphql`
  query($id: String!) {
    event(id: { eq: $id }) {
      title
      tanggal: date(formatString: "dddd, DD MMMM YYYY", locale: "id")
      timestamp: date(formatString: "x")
    }
  }
`;
